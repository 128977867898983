<template>
    <div v-if="show" aria-hidden="true" class="modal fade show d-block" tabindex="-1">
        <div class="modal-dialog" :class="{'modal-xl': props.large}">
            <div class="modal-content">
                <div v-if="props.loading" class="loading w-100 h-100 position-absolute">
                    <div class="d-flex justify-content-center align-items-center w-100 h-100">
                        <Loading color="text-white" />
                    </div>
                </div>
                <div class="modal-header">
                    <slot name="header">
                        <h5 class="modal-title">{{ title }}</h5>
                        <button v-if="props.closable && !props.loading" @click="closeModal" class="btn-close" type="button"></button>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        // body goes here
                    </slot>
                </div>
                <div class="modal-footer">
                    <slot name="footer">
                        <button v-if="props.closable && !props.loading" @click="closeModal" class="btn btn-light" type="button">Close</button>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import Loading from "@components/Loading.vue";

    const props = withDefaults(
        defineProps<{
            title?: string,
            show: boolean,
            closable?: boolean,
            loading?: boolean,
            large?: boolean,
        }>(),{
            show: false,
            closable: true,
            loading: false,
            large: false,
        }
    );

    const emit = defineEmits(['update:show']);

    const closeModal = (): void => {
        if (! props.closable){
            return;
        }
        
        emit('update:show', false);
    }
</script>

<style scoped>
    .modal {
        background: rgba(0,0,0,0.75);
    }
    
    .loading {
        background: rgba(0,0,0,0.5);
        z-index: 1002
    }
</style>
