<template>
    <div class="mb-2 d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <SvgIcon
                type="mdi"
                :path="mdiCarClock"
                class="w-5 h-5 text-secondary"
            ></SvgIcon>
            <div class="ms-1">
                Booking
                <BookingReferenceNumber
                    :booking="bookingModel"
                ></BookingReferenceNumber>
                <span
                    v-if="booking.reschedule_identifier"
                    v-tooltip="'This booking is part of a reocurrance'"
                >
                                        <SvgIcon
                                            type="mdi"
                                            :path="mdiRepeatVariant"
                                            :size="iconSize"
                                            class="me-1 text-info"
                                        ></SvgIcon>
                                    </span>
                <span>
                    {{ booking.daily_leg_order }}
                </span>
            </div>
        </div>
        <div>
            <button
                v-if="showCloseIcon"
                @click="$emit('closed')"
                type="button"
                class="btn-close"
                title="Close Panel"
                aria-label="Close"
            ></button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-7">
            <p v-if="bookingModel.notes">{{ bookingModel.notes }}</p>
            <div class="border border-1 p-1 rounded">
                <BookingRouteMap :booking="bookingModel"></BookingRouteMap>
            </div>
            <dl
                class="row"
                v-if="
                    bookingModel.additional_minutes_required ||
                    bookingModel.maximum_transit_minutes
                "
            >
                <template v-if="bookingModel.additional_minutes_required">
                    <dt class="col-3">Additional Time Required</dt>
                    <dd class="col-9">
                        {{
                            $filters.format_minutes(
                                bookingModel.additional_minutes_required
                            )
                        }}
                    </dd>
                </template>
                <template v-if="bookingModel.maximum_transit_minutes">
                    <dt class="col-3">Maximum Transit Time</dt>
                    <dd class="col-9">
                        {{
                            $filters.format_minutes(
                                bookingModel.maximum_transit_minutes
                            )
                        }}
                    </dd>
                </template>
            </dl>
            <div class="tl-container mt-3">
                <div class="fw-bold">{{ formattedBookingDate }}</div>
                <div class="tl-item tl-future">
                    <div class="tl-dot py-2"></div>
                    <div class="tl-content py-2 d-flex align-items-start w-100">
                        <BookingTime :booking="bookingModel" type="origin"/>
                        <span class="mx-1">–</span>
                        <span>
                            {{ bookingModel.origin.formatted_address }}
                        </span>
                    </div>
                </div>
                <div
                    v-for="stop in bookingModel.stops"
                    class="tl-item tl-future"
                >
                    <div class="tl-dot py-2"></div>
                    <div class="tl-content py-2">
                        <DateTime
                            :date-time="stop.arrive_no_later_than"
                            format="short time"
                        ></DateTime>
                        – {{ stop.location.formatted_address }}
                    </div>
                </div>
                <div class="tl-item tl-destination tl-future">
                    <div class="tl-dot py-2"></div>
                    <div
                        class="tl-content pt-3 pb-2 d-flex align-items-start w-100"
                    >
                        <BookingTime
                            :booking="bookingModel"
                            type="destination"
                        />
                        <span class="mx-1">–</span>
                        <span>
                            {{ bookingModel.destination.formatted_address }}
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="bookingModel.status === 'cancelled'" class="border rounded p-2">
                <div class="row">
                    <dt class="col-6">Booking Cancelled:</dt>
                    <dd class="col-6">{{ DatetimeHelper.toLocaleDateTime(bookingModel.cancelled_at) }}
                        <div>by {{ bookingModel.cancelled_by?.name ?? 'Unknown' }}
                            <span v-tooltip="'Bookings cancelled before version 2.7 may not have recorded the person who cancelled the booking. This information will be visible via audits.'">
                                <SvgIcon
                                    :path="mdiHelpCircle"
                                    class="h-4 w-4 text-primary"
                                    type="mdi"
                                ></SvgIcon>
                            </span>
                        </div>
                    </dd>
                </div>
                <div class="row">
                    <dt class="col-6">Cancelled Reason:</dt>
                    <dd class="col-6">{{ bookingModel.booking_cancelation_reason?.name }}</dd>
                </div>
                <div class="row">
                    <dt class="col-6">Cancellation Comment:</dt>
                    <dd class="col-6">{{ bookingModel.cancellation_comment }}</dd>
                </div>
            </div>
        </div>
        <div class="col-lg-5 border-start">
            <div class="d-flex justify-content-between align-items-center">
                <div class="mb-2">
                    <BookingStatusDropDown :booking="booking"/>
                </div>
                <div v-if="hasRevertable">
                    <div class="overlay-cross" @click="showRevertBookingModal(booking)">
                        <i class="mdi mdi-account-plus text-info" style="font-size: 22px;"></i>
                    </div>
                </div>
            </div>
            <RevertBooking
                :selected-booking="selectedBooking"
                :is-modal="false"
                :show-panel="showRevertPanel"
            />
            <dl class="row">
                <dt class="col-4">Client</dt>
                <dd class="col-8">
                    <ClientAvatar
                        :size="'extra-small'"
                        class="me-1"
                        :client="bookingModel.client"
                    ></ClientAvatar>
                    <ClientName :client="bookingModel.client"></ClientName>
                </dd>
                <template v-if="(bookingModel?.companions?.length ?? 0) > 0">
                    <dt class="col-4">Companion</dt>
                    <dd class="col-8">
                        <div v-for="companion in companions" class="companion">
                            <div class="companion__relation">{{ companion?.relationship }}</div>
                            {{ companion?.given_names }} {{ companion?.last_name }}
                        </div>
                    </dd>
                </template>
                <template v-if="bookingModel.priority">
                    <dt class="col-4">Priority</dt>
                    <dd class="col-8">
                        <SvgIcon
                            type="mdi"
                            :path="mdiChevronDoubleUp"
                            class="me-1 text-danger"
                            v-if="bookingModel.priority === 'High'"
                        ></SvgIcon>
                        <SvgIcon
                            type="mdi"
                            :path="mdiChevronDoubleDown"
                            class="me-1 text-info"
                            v-if="bookingModel.priority === 'Low'"
                        ></SvgIcon>
                        {{ bookingModel.priority }}
                    </dd>
                </template>
                <template v-if="bookingModel.purpose">
                    <dt class="col-4">Purpose</dt>
                    <dd class="col-8">{{ bookingModel.purpose }}</dd>
                </template>
                <template v-if="bookingModel.treatment_type">
                    <dt class="col-4">Treatment</dt>
                    <dd class="col-8">
                        {{ bookingModel.treatment_type.description }}
                    </dd>
                </template>
                <template v-if="bookingModel.estimated_distance">
                    <dt class="col-4">Estimated Distance</dt>
                    <dd class="col-8">
                        {{
                            $filters.format_distance(
                                bookingModel.estimated_distance
                            )
                        }}
                    </dd>
                </template>
                <template v-if="bookingModel.service_type">
                    <dt class="col-4">Service Type</dt>
                    <dd class="col-8">
                        <EditBookingServiceType
                            :key="bookingModel.uuid"
                            :booking="bookingModel"
                            @updated="updateBooking"
                        />
                    </dd>
                </template>
                <template v-if="bookingModel.uuid">
                    <dt class="col-4">Booking Fee</dt>
                    <dd class="col-8">
                        <EditBookingFee
                            :key="bookingModel.uuid"
                            :booking="bookingModel"
                            @updated="updateBooking"
                        />
                    </dd>
                </template>
                <template v-if="userCan('view_activity_logs')">
                    <dt class="col-4">History</dt>
                    <dd class="col-8">
                        <a href="javascript: void(0)" @click="activityLogs.show('booking', booking.uuid)">View Activity Logs</a>
                    </dd>
                </template>
                <template v-if="windowRef?.user_type == 'Admin'">
                    <dt class="col-4">Vehicle GPS updates</dt>
                    <dd class="col-8">
                        <a href="javascript: void(0)" @click="driverPositionUpdates.showForBooking(booking)">View GPS updates</a>
                    </dd>
                </template>
                <div>
                    <dt class="col-4">Booking Created</dt>
                    <dd class="col-8">
                        {{ DatetimeHelper.toLocaleDateTime(bookingModel.created_at) }}
                        <div>
                            by {{ bookingModel.created_by_name ?? 'Unknown' }}
                            <span v-tooltip="'Bookings created before version 2.7 may not have recorded the person who created the booking. This information will be visible via audits.'">
                                <SvgIcon
                                    :path="mdiHelpCircle"
                                    class="h-4 w-4 text-primary"
                                    type="mdi"
                                ></SvgIcon>
                            </span>
                        </div>
                    </dd>
                </div>
            </dl>
            <div class="mb-3">
                <label class="form-label">Fare Collection Method</label>
                <BookingFareColletionMethod
                    :booking="bookingModel"
                    @updated="updateBooking"
                />
            </div>
            <div class="mb-3">
                <label class="form-label">General Booking Notes</label>
                <BookingNoteEditable
                    :booking="bookingModel"
                    @updated="updateBooking"
                    :noteField="'notes'"
                />
            </div>
            <div class="mb-3">
                <label class="form-label">Message for Driver</label>
                <BookingNoteEditable
                    :booking="bookingModel"
                    @updated="updateBooking"
                    :noteField="'client_notes'"
                />
            </div>
            <div
                v-if="
                    !bookingModel.journey &&
                    !inPast(
                        bookingModel.planned_origin_time ??
                            bookingModel.planned_destination_time
                    )
                "
            >
                <p class="mt-3">
                    <template v-if="!journeyToAllocate">
                        <strong
                        >This booking is currently not allocated to a
                            vehicle</strong
                        >
                    </template>
                    <template v-if="journeyToAllocate"
                    >This booking will be allocated to
                    </template
                    >
                </p>
                <div
                    v-if="!journeyToAllocate && recommendedJourney"
                    class="d-flex flex-column align-items-start mb-2"
                >
                    <div
                        v-if="isRecommendedJourney"
                        class="text-xxs bg-primary text-white px-1 rounded-top"
                        style="padding-top: 0.2rem"
                    >
                        RECOMMENDED
                    </div>
                    <div
                        class="d-flex w-100 py-2 ps-3 pe-2 rounded-bottom rounded-end border border-2 border-primary"
                    >
                        <div class="d-flex align-items-center flex-grow-1">
                            <div style="max-width: 80px" class="me-3">
                                <img
                                    :src="`/images/vehicles/${recommendedJourney?.vehicle?.type?.image ? recommendedJourney.vehicle.type.image : 'sedan'}.png`"
                                    class="img-fluid"
                                    alt="Vehicle Icon"
                                />
                            </div>
                            <div>
                                <strong>{{ recommendedJourney?.vehicle?.description }}</strong>
                                <div>
                                    <template v-if="recommendedJourney.driver">
                                        {{ recommendedJourney.driver?.name }}
                                    </template>
                                    <template v-else>
                                        <em>No driver allocated</em>
                                    </template>
                                </div>
                                <div>
                                    Availability:
                                    <DateTime :date-time="recommendedJourney.start_time" format="short time"></DateTime>
                                    —
                                    <DateTime
                                        :date-time="recommendedJourney.end_time"
                                        format="short time"
                                    ></DateTime>
                                </div>
                            </div>
                        </div>
                        <div>
                            <button
                                @click="
                                    allocateBooking(
                                        bookingModel,
                                        recommendedJourney
                                    )
                                "
                                :disabled="savingChanges"
                                class="btn btn-sm btn-primary d-flex align-items-center"
                                v-tooltip="'Allocate to this vehicle run'"
                            >
                                <template v-if="!savingChanges">
                                    <span class="visually-hidden"
                                    >Allocate</span
                                    >
                                    <SvgIcon
                                        :path="mdiCarArrowRight"
                                        class="w-5"
                                        type="mdi"
                                    ></SvgIcon>
                                </template>
                                <template v-if="savingChanges">
                                    <span class="visually-hidden">Saving</span>
                                    <div
                                        class="spinner-grow spinner-grow-sm"
                                        role="status"
                                    ></div>
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="loadingJourneys">
                    <div
                        class="spinner-grow spinner-grow-sm me-1"
                        role="status"
                    ></div>
                    Loading Vehicles
                </div>
                <div
                    v-else-if="orderedJourneys?.length"
                    v-dropdown
                    :class="{ 'd-none': journeyToAllocate }"
                >
                    <button
                        :disabled="loadingJourneys"
                        :class="
                            'btn dropdown-toggle ' +
                            (recommendedJourney ? 'btn-light' : 'btn-primary')
                        "
                        type="button"
                        :id="'allocateBookingToJourneyDropdown_' + componentId"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <template v-if="!loadingJourneys">
                            <template v-if="!recommendedJourney"
                            >Allocate This Booking
                            </template
                            >
                            <template v-if="recommendedJourney"
                            >Choose a different vehicle
                            </template
                            >
                        </template>
                        <template v-if="loadingJourneys">
                            <div
                                class="spinner-grow spinner-grow-sm me-1"
                                role="status"
                            ></div>
                            Loading Vehicles
                        </template>
                    </button>
                    <ul
                        :aria-labelledby="
                            'allocateBookingToJourneyDropdown_' + componentId
                        "
                        class="dropdown-menu"
                        style="max-height: 60vh; overflow-y: auto"
                    >
                        <template v-for="journey in orderedJourneys">
                            <li
                                v-if="
                                    !recommendedJourney ||
                                    recommendedJourney.uuid !== journey.uuid
                                "
                            >
                                <a
                                    @click="journeyToAllocate = journey"
                                    class="dropdown-item d-flex align-items-center py-2"
                                    href="javascript: void(0)"
                                >
                                    <div style="max-width: 60px" class="me-3">
                                        <img
                                            :src="`/images/vehicles/${journey?.vehicle?.type?.image ? journey.vehicle.type.image : 'sedan'}.png`"
                                            class="img-fluid"
                                            alt="Vehicle Icon"
                                        />
                                    </div>
                                    <div>
                                        <strong>{{ journey?.vehicle?.description }}</strong>
                                        <div>
                                            <template v-if="journey.driver">
                                                {{ journey.driver?.name }}
                                            </template>
                                            <template v-else>
                                                <em>No driver allocated</em>
                                            </template>
                                        </div>
                                        <div>
                                            Availability:
                                            <DateTime
                                                :date-time="journey.start_time"
                                                format="short time"
                                            ></DateTime>
                                            —
                                            <DateTime
                                                :date-time="journey.end_time"
                                                format="short time"
                                            ></DateTime>
                                        </div>
                                    </div>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
                <div class="alert alert-warning" v-else>
                    No availability can be found to allocate this booking.
                </div>
            </div>
            <div
                v-if="
                    !bookingModel.journey &&
                    inPast(
                        bookingModel.planned_origin_time ??
                            bookingModel.planned_destination_time
                    )
                "
            >
                This booking was not allocated to a vehicle run.
            </div>
            <template v-if="journey">
                <h5 v-if="bookingModel.journey">Allocated to</h5>
                <div
                    class="d-flex align-items-start py-2 ps-3 pe-2 rounded border mb-2"
                >
                    <div class="row align-items-center flex-grow-1">
                        <div class="d-none d-xxl-block col-xxl-4">
                            <img
                                :src="`/images/vehicles/${journey?.vehicle?.type?.image ? journey.vehicle.type.image : 'sedan'}.png`"
                                class="img-fluid"
                                alt="Vehicle Icon"
                            />
                        </div>
                        <div class="col-12 col-xxl-8">
                            <strong>{{ journey.vehicle.description }}</strong>
                            <div>
                                <template v-if="journey.driver">{{
                                        journey.driver?.name
                                    }}
                                </template>
                                <template v-else
                                ><em>No driver allocated</em></template
                                >
                            </div>
                            <div>
                                Availability:
                                <DateTime
                                    :date-time="journey.start_time"
                                    format="short time"
                                ></DateTime>
                                —
                                <DateTime
                                    :date-time="journey.end_time"
                                    format="short time"
                                ></DateTime>
                            </div>
                        </div>
                    </div>
                    <template v-if="savingChanges">
                        <div
                            class="spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </template>
                    <template
                        v-if="
                            !savingChanges &&
                            !inPast(
                                bookingModel.planned_origin_time ??
                                    bookingModel.planned_destination_time
                            )
                        "
                    >
                        <button
                            @click="deAllocate(bookingModel)"
                            type="button"
                            class="btn-close"
                            title="De-allocate this booking"
                            aria-label="Close"
                        ></button>
                    </template>
                </div>
                <button
                    v-if="journeyToAllocate && !bookingModel.journey"
                    @click="allocateBooking(bookingModel, journey)"
                    :disabled="savingChanges"
                    class="btn btn-primary d-flex align-items-center"
                >
                    <template v-if="!savingChanges">Save Changes</template>
                    <template v-if="savingChanges">
                        <Spinner size="small" class="me-2"/>
                    </template>
                </button>
            </template>
            <div class="mt-1 d-flex justify-content-end column-gap-1">
                <Button v-if="!booking.deleted_at"
                        :disabled="(Luxon.fromISO(booking.date) < Luxon.now().startOf('day')) || booking.client?.deactivated_at"
                        class="btn btn-primary align-items-center"
                        @click="onBookingEditClick">
                    <SvgIcon :path="mdiPencil"
                             class="w-4 h-4 me-1"
                             type="mdi"></SvgIcon>
                    Edit Booking
                </Button>
                <Button v-if="!booking.deleted_at"
                        :disabled="booking.status !== 'future'"
                        class="btn btn-danger align-items-center"
                        @click="onBookingDeleteClick">
                    <SvgIcon :path="mdiTrashCan"
                             class="w-4 h-4 me-1"
                             type="mdi"></SvgIcon>
                    Delete Booking
                </Button>
            </div>

        </div>
        <ConfirmationModal id="confirmDeleteBookingModal" @confirmed="onBookingDeleteConfirmed">
            <template #text>
                <p v-if="props.booking.sequence_identifier">
                    Are you sure you want to delete the following bookings?
                    <ul class="mt-2">
                        <li v-for="booking in props.booking.sequenceBookings"><a :href="route('bookings.show', {booking:booking.uuid})">{{ booking.uuid }}</a></li>
                    </ul>
                </p>
                <p v-else>Are you sure you want to delete booking {{ props.booking.uuid }}?</p>
            </template>
        </ConfirmationModal>
    </div>
</template>

<script setup lang="ts">
import ClientName from "@components/Clients/ClientName.vue";
import DateTime from "@components/DateTime.vue";
import {ToastInterface} from "@components/toast";
import {ApiError, ApiErrorResponse} from "@customTypes/LaravelCommonTypes";
import SvgIcon from "@jamescoyle/vue-icon";
import {
    mdiChevronDoubleDown,
    mdiChevronDoubleUp,
    mdiCarArrowRight,
    mdiCarClock,
    mdiHelpCircle, mdiRepeatVariant, mdiTrashCan,
} from "@mdi/js";
import BookingRouteMap from "@components/Bookings/BookingRouteMap.vue";
import ClientAvatar from "@components/Clients/ClientAvatar.vue";
import BookingReferenceNumber from "@components/Bookings/BookingReferenceNumber.vue";
import {uuid} from "vue-uuid";
import {ref, computed, onBeforeMount, onMounted, watch, inject, getCurrentInstance} from "vue";
import axios from "axios";
import {DateTime as Luxon} from "luxon";
import Button from "@components/Button.vue";
import BookingStatusDropDown from "@components/Bookings/BookingStatusDropDown.vue";
import Spinner from "@components/Spinner.vue";
import BookingTime from "@components/Bookings/BookingTime.vue";
import EditBookingFee from "@components/Bookings/EditableFields/EditBookingFee.vue";
import EditBookingServiceType from "@components/Bookings/EditableFields/EditBookingServiceType.vue";
import BookingNoteEditable from "@components/Bookings/BookingNoteEditable.vue";
import BookingFareColletionMethod from "@components/Bookings/BookingFareColletionMethod.vue";
import {mdiPencil} from "@mdi/js";
import Booking, {BookingInterface} from "@classes/Booking";
import DatetimeHelper from "@classes/DateHelpers/DatetimeHelper";
import RevertBooking from "@components/Bookings/RevertBookingModal.vue";
import {storeToRefs} from "pinia";
import {useRevertBookingStore} from "@stores/RevertBookingStore.";
import {useAllocationStore} from "@stores/AllocationStore";
import {formatDate, userCan} from '@components/Utils';
import {ConfirmationModal} from "@components/index.js";
import {Modal} from "bootstrap";
import useWindow from "@hooks/useWindow";

const toast: ToastInterface = <ToastInterface>inject('toast');

const windowRef = useWindow();

const revertBookingStore = useRevertBookingStore();
const {storeSelectedBookingToRevert} = storeToRefs(revertBookingStore);

const allocationStore = useAllocationStore();
const {allocationErrors} = storeToRefs(allocationStore);
const {checkAllocationRequirements} = allocationStore;

const activityLogs = inject("activityLogs");
const driverPositionUpdates = inject("driverPositionUpdates");

const props = defineProps({
    booking: {
        type: Object,
        required: true,
    },
    showCloseIcon: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits([
    "closed",
    "bookingAllocated",
    "bookingDetailsUpdated",
]);

const bookingModel: BookingInterface = ref<BookingInterface>(props.booking);
const fetchingAllJourneys = ref(false);
const fetchingOptimisedVehicleList = ref(false);
const journeys = ref(null);
const journeyToAllocate = ref(null);
const orderedVehicleList = ref(null);
const orderedVehicleListIsRecommended = ref(false);
const isRecommendedJourney = ref(false);
const savingChanges = ref(false);
const componentId = ref(uuid.v4());
const companions = ref([]);
const confirmDeleteBookingModal = ref<Modal | null>(null);

const updateBooking = (booking) => {
    emit("bookingDetailsUpdated");
    bookingModel.value = booking;
};

const bookingEditUrl = computed(() => {
    return route("bookings.edit", {booking: bookingModel.value.uuid});
});

const journey = computed(() => {
    return bookingModel.value.journey
        ? bookingModel.value.journey
        : journeyToAllocate.value;
});

const loadingJourneys = computed(() => {
    return fetchingAllJourneys.value || fetchingOptimisedVehicleList.value;
});

const orderedJourneys = computed(() => {
    if (!journeys.value || !Array.isArray(orderedVehicleList.value)) {
        return [];
    }

    const journeysCopy = JSON.parse(JSON.stringify(journeys.value));
    return journeysCopy.sort((journeyA, journeyB) => {
        const positionA = orderedVehicleList.value.indexOf(
            journeyA.vehicle.uuid
        );
        const positionB = orderedVehicleList.value.indexOf(
            journeyB.vehicle.uuid
        );

        if (positionA < 0 && positionB < 0) {
            // neither journeys are found in the rankings
            return 0;
        }

        isRecommendedJourney.value = true;

        if (positionA < 0) {
            // journey A isn't found in the ranking, but journey B is
            return 1;
        }

        if (positionB < 0) {
            // journey B isn't found in the ranking, but journey A is
            return -1;
        }

        return positionA < positionB ? -1 : 1;
    });
});

const recommendedJourney = computed(() => {
    if (!journeys.value || !Array.isArray(orderedVehicleList.value)) {
        return null;
    }

    return orderedJourneys.value[0];
});

const formattedBookingDate = computed(() => {
    let timezone = window.timezone.toString();
    return formatDate(Luxon.fromISO(props.booking.date, {zone: timezone}), 'full date')
});

const allocateBooking = function (booking, journey) {
    savingChanges.value = true;

    if (!checkAllocationRequirements([booking], journey)) {
        return false;
    }

    Booking.saveAllocationChanges({
        bookings: [
            {
                uuid: booking.uuid,
                journey_uuid: journey.uuid,
            },
        ],
    }).then((response) => {
        bookingModel.value.journey = journey;
        setTimeout(() => {
            emit("bookingAllocated", booking);
        }, 2000);
    })
        .catch((error) => {
            console.error(error);
            toast.error('Failed to Allocate Booking to Vehicle Run');
        })
        .finally(() => {
            savingChanges.value = false;
        })
};

const bookingStatusBadgeClass = function (status) {
    switch (status) {
        case "verified":
            return "bg-success";
        case "standby":
            return "bg-info";
        case "future":
            return "bg-primary";
        case "cancelled":
        case "unmet":
            return "bg-danger";
        case "waiting_verification":
        case "transferred":
        case "posted":
            return "bg-secondary";
    }
};

const deAllocate = function (booking) {
    savingChanges.value = true;
    if (!booking.journey && journeyToAllocate.value) {
        journeyToAllocate.value = null;
        return;
    }

    Booking.saveAllocationChanges({
        bookings: [
            {
                uuid: booking.uuid,
                journey_uuid: null,
            },
        ],
    }).then((response) => {
        savingChanges.value = false;
        journeyToAllocate.value = null;
        bookingModel.value.journey = null;

        getOptimisedVehicleList();
    });
};

const getJourneys = function () {
    fetchingAllJourneys.value = true;

    const date = bookingModel.value
        ? Luxon.fromISO(bookingModel.value.date).toFormat("yyyy-MM-dd")
        : Luxon.local().toISODate();

    axios
        .get(route("api.journeys.index"), {
            params: {
                date: date,
                location_restrictions: [props.booking.origin.uuid, props.booking.destination.uuid],
            },
        })
        .then((result) => {
            journeys.value = result.data.data;
            fetchingAllJourneys.value = false;
        });
};

const getOptimisedVehicleList = function () {
    fetchingOptimisedVehicleList.value = true;
    axios.get(
        route("optimiser.vehicle-recommendations", {
            booking: bookingModel.value.uuid,
        })
    )
        .then((response) => {
            orderedVehicleList.value = response.data.vehicles;
            orderedVehicleListIsRecommended.value = response.data.isRecommended;
        })
        .catch((error) => {
            orderedVehicleList.value = null;
        })
        .finally(() => {
            fetchingOptimisedVehicleList.value = false;
        });
};

const hasRevertable = computed(() => {
    if (!props.booking.revert_requested) {
        return false;
    }

    return true;
});

const selectedBooking = ref({});
const showRevertPanel = ref(false);
const showRevertBookingModal = (booking) => {
    selectedBooking.value = {};

    setTimeout(function () {
        selectedBooking.value = booking;
        showRevertPanel.value = true;
    }, 200);
}

const getClientCompanion = () => {
    axios.get(route('api.bookings.companions.index', {
        booking: bookingModel.value.uuid
    })).then((resp) => {
        companions.value = resp.data.data
    })
}

const onBookingEditClick = () => {
    window.location.href = bookingEditUrl.value;
}

const onBookingDeleteClick = () => {
    confirmDeleteBookingModal.value?.show();
}

const onBookingDeleteConfirmed = async () => {
    try {
        await Booking.destroy(bookingModel.value);
        toast.success("Booking(s) deleted.");
        setTimeout(() => window.location.href = route('bookings.index'), 1500);
    } catch (error: unknown) {
        toast.error((error as ApiError).response.data.message)
    } finally {
        confirmDeleteBookingModal.value?.hide();
    }
}

watch(() => revertBookingStore.storeSelectedBookingToRevert, (store) => {
    if (bookingModel.value && bookingModel.value?.uuid === store.uuid) {
        bookingModel.value.revert_requested = '';
        bookingModel.value.revert_reason = '';

        const instance = getCurrentInstance();
        instance?.proxy?.$forceUpdate();
    }
})

onMounted(() => {
    getJourneys();

    if (!bookingModel.value?.journey) {
        getOptimisedVehicleList();
    }

    if ((bookingModel.value?.companions?.length ?? 0) > 0) {
        getClientCompanion();
    }

    confirmDeleteBookingModal.value = new Modal(document.getElementById("confirmDeleteBookingModal") as Element)
});
</script>

<style scoped>
.mdi-repeat {
    margin-left: 5px;
    color: #0351BF;
}
</style>
