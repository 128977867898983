import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import Optimiser, {
    OptimiserConstraintViolation,
    OptimiserScheduleRequestRawResponse,
    OptimiserScheduleResponseJourneyRouteObject,
    OptimiserUnscheduledReason,
} from "@classes/Optimiser";
import { uuid } from "vue-uuid";
import JourneyApi, { JourneyStatus } from "@classes/JourneyApi";
import { DateTime } from "luxon";
import Booking, {
    BookingStatus,
    SaveAllocationChangesRequestInterface,
} from "@classes/Booking";
import { cloneDeep, each } from "lodash";
import BookingDatetime from "@classes/DateHelpers/BookingDatetime";
import JourneyDatetime from "../classes/DateHelpers/JourneyDatetime";
import Driver from "../classes/Driver";
import { useRegionStore } from "@stores/RegionStore";
import BookingResource from "@customTypes/resources/BookingResource";
import { useMessageStore } from "@stores/MessageStore";
import { LocationInterface } from "../classes/Location";

export interface RegionAllocationState {
    region: string;
    solutionId?: string;
    bookings: string[];
    journeys: string[];
    completed: boolean;
}

export interface AllocationError {
    [key: string]: string;
}

const timeComparisonFormat = "yyyy-MM-dd HH:mm";

export const useAllocationStore = defineStore("allocationStore", () => {
    const { selectedRegion } = storeToRefs(useRegionStore());
    const { addToastMessage } = useMessageStore();

    const adjustments = ref<any[]>([]);
    //originalBookings saves the bookings as they were when the page was loaded, before the user makes any changes
    const originalBookings = ref<App.Models.Booking[]>([]);
    const bookings = ref<App.Models.Booking[]>([]);

    const originalJourneys = ref<App.Models.Journey[]>([]);
    const journeys = ref<App.Models.Journey[]>([]);
    const errors = ref<any[]>([]);
    const autoAllocationDisabledErrors = ref<string[]>([]);
    const autoAllocationResponseValidationErrors = ref<string[]>([]);
    const bookingTimeValidationErrors = ref<any[]>([]);
    const vehicleRunTimeValidationErrors = ref<any[]>([]);
    const changedSaved = ref(false);
    const optimiserSolutions = ref<
        {
            region: string;
            solution: OptimiserScheduleRequestRawResponse;
        }[]
    >([]);
    const selectedJourney = ref<App.Models.Journey | undefined>();
    const journeyIndexRequestUuid = ref(uuid.v4());
    const loadingJourneys = ref(false);
    const date = ref(DateTime.now().toFormat("yyyy-MM-dd"));
    const showManualOverrideWarning = ref(false);
    const unscheduledByOptimiser = ref<any[]>([]);
    const optimiserProcessingModal = ref<bootstrap.Modal | null>(null);
    const regionAllocationStates = ref<RegionAllocationState[]>([]);
    const reloadTablesKey = ref(Math.random());
    const loadingOptimiserSolutions = ref(false);
    const loadingBookings = ref(false);
    const savingChanges = ref(false);
    const optimiserSettings = ref({});
    const optimiserSettingsModal = ref<bootstrap.Modal | null>(null);
    const optimiserUnscheduledModal = ref<bootstrap.Modal | null>(null);
    const regions = ref<App.Models.Region[]>([]);
    const selectedBookingsInAllocatedList = ref<App.Models.Booking[]>([]);
    const selectedBookingsInUnAllocatedList = ref<App.Models.Booking[]>([]);
    const selectedJourneysForAllocation = ref<App.Models.Journey[]>([]);
    const expandedVehicleIndexes = ref<number[]>([]);
    const allocationErrors = ref<AllocationError[]>([]);
    let regionAutoAllocationSolutions = ref([]);
    const allocationSearchPhrase = ref("");
    const allocationSearchBy = ref("Clients");
    const allocationAllocatedSectionSearchPhrase = ref("");
    const allocationAllocatedSectionSearchBy = ref("Clients");
    const autoAllocatePressed = ref(false);

    const allocatedBookingsFilters = ref<{
        depots: LocationInterface[];
    }>({
        depots: [],
    });

    const getJourneys = () => {
        journeyIndexRequestUuid.value = uuid.v4();
        const currentRequestId = journeyIndexRequestUuid.value;
        loadingJourneys.value = true;
        // revert below once we have proper solution TR-1717
        //Journey.index({ date: date.value }).then((response) => {
        return JourneyApi.tempIndex({ date: date.value }).then((response) => {
            if (currentRequestId !== journeyIndexRequestUuid.value) {
                // request was old, ignore
                return;
            }

            let journeysNotCancelled =
                response.data.data?.filter(
                    (i) => i?.status !== JourneyStatus.Cancelled
                ) ?? [];

            journeys.value = cloneDeep(journeysNotCancelled);
            originalJourneys.value = cloneDeep(journeysNotCancelled);

            expandedVehicleIndexes.value = [0];

            selectedJourney.value = vehicles.value?.[0]?.runs?.[0];

            loadingJourneys.value = false;
        });
    };

    const getBookings = () => {
        loadingBookings.value = true;
        // this is temporary solution
        return Booking.tempIndex({
            //Booking.index({
            date: DateTime.fromFormat(date.value, "yyyy-MM-dd"),
            // 'timezone': timezone.value,
            order_by: "planned_origin_time",
        })
            .then((response) => {
                bookings.value = cloneDeep(response.data);
                originalBookings.value = cloneDeep(response.data);
            })
            .catch((err) => {
                console.error(err);
                addToastMessage("error", "Failed to fetch Bookings");
            })
            .finally(() => {
                loadingBookings.value = false;
            });
    };

    const autoAllocateRegion = (state: RegionAllocationState) => {
        let updatedRegionAllocationStates = regionAllocationStates.value;

        let regionsStateIndex = updatedRegionAllocationStates.findIndex(
            (i) => i.region === state.region
        );

        const bookingUuidsForRegion = selectedBookingsInUnAllocatedList.value
            .filter((booking) => booking.region?.uuid === state.region)
            .map((booking) => booking.uuid);

        const journeyUuidsForRegion = selectedJourneysForAllocation.value
            .filter((journey) => journey.region?.uuid === state.region)
            .map((i) => i.uuid);

        updatedRegionAllocationStates[regionsStateIndex].bookings =
            bookingUuidsForRegion;

        updatedRegionAllocationStates[regionsStateIndex].journeys =
            journeyUuidsForRegion;

        regionAllocationStates.value = updatedRegionAllocationStates;

        if (
            updatedRegionAllocationStates[regionsStateIndex].journeys.length ===
                0 ||
            updatedRegionAllocationStates[regionsStateIndex].bookings.length ===
                0
        ) {
            regionAllocationStates.value[regionsStateIndex].completed = true;
            return;
        }

        const optimiserScheduleParameters = {
            optimiser_solution:
                regionAllocationStates.value[regionsStateIndex].solutionId,
            bookings: regionAllocationStates.value[regionsStateIndex].bookings,
            journeys: regionAllocationStates.value[regionsStateIndex].journeys,
            date: date.value,
        };

        Optimiser.schedule(optimiserScheduleParameters)
            .then((response) => {
                optimiserSolutions.value.push({
                    region: state.region,
                    solution: response.data,
                });

                selectedBookingsInAllocatedList.value = [];
                selectedBookingsInUnAllocatedList.value = [];
                selectedJourneysForAllocation.value = [];

                let region = regions.value.find((i) => i.uuid == state.region);

                if ((response?.data?.journeys ?? []).length === 0) {
                    if (Object.values(response.data.messages).length > 0) {
                        for (const [key, value] of Object.entries(
                            response.data.messages
                        )) {
                            autoAllocationResponseValidationErrors.value.push(
                                key + " " + value
                            );
                        }
                    }
                    autoAllocationResponseValidationErrors.value.push(
                        `No Vehicle Run was allocated with bookings in '${
                            region?.name ?? ""
                        }' region`
                    );
                    return;
                }

                //Populate scheduled items
                (response?.data?.journeys ?? []).forEach(
                    (optimiserJourneyObject) => {
                        let journey_uuid =
                            optimiserJourneyObject.vehicle_id.split("---")[1];

                        //See if any of the journeys we have in the store match the optimiser journey
                        let matchingJourney = journeys.value.find((journey) => {
                            return journey.uuid == journey_uuid;
                        });

                        if (!matchingJourney) {
                            autoAllocationResponseValidationErrors.value.push(
                                `No Vehicle Run matched optimiser response for ${optimiserJourneyObject.vehicle_id}`
                            );
                            return;
                        }

                        if (optimiserJourneyObject.driver_id) {
                            Driver.show(optimiserJourneyObject.driver_id).then(
                                (response) => {
                                    if (matchingJourney) {
                                        matchingJourney.driver = response.data;
                                    }
                                }
                            );
                        }

                        matchingJourney.start_time = DateTime.fromISO(
                            optimiserJourneyObject.depart_depot
                        ).toISO();
                        matchingJourney.end_time = DateTime.fromISO(
                            optimiserJourneyObject.arrive_at_depot
                        ).toISO();

                        let journeyRoutes: OptimiserScheduleResponseJourneyRouteObject[] =
                            optimiserJourneyObject?.journey_route ?? [];

                        if (journeyRoutes.length === 0) {
                            autoAllocationResponseValidationErrors.value.push(
                                `None of the selected bookings could be assigned to Vehicle Run ${matchingJourney.uuid}`
                            );
                            return;
                        }

                        //Handle breaks
                        let breakJourneyRoute = journeyRoutes.find(
                            (journeyRoute) =>
                                journeyRoute.node_type == 4 &&
                                journeyRoute.begin &&
                                journeyRoute.depart
                        );

                        matchingJourney.breaks = [];

                        if (breakJourneyRoute) {
                            let newBreaks = [
                                {
                                    start_time: DateTime.fromISO(
                                        breakJourneyRoute.begin
                                    ).toISO(),
                                    end_time: DateTime.fromISO(
                                        breakJourneyRoute.depart
                                    ).toISO(),
                                },
                            ];
                            //@ts-ignore
                            matchingJourney.breaks = newBreaks;
                        }

                        journeyRoutes.forEach((journeyRoute) => {
                            if (!journeyRoute.booking_id) {
                                return;
                            }
                            let booking = bookings.value.find(
                                (booking) =>
                                    booking.uuid === journeyRoute.booking_id
                            );
                            if (!booking) {
                                return;
                            }

                            booking.journey = matchingJourney;
                            if (
                                journeyRoute.node_type == 0 &&
                                journeyRoute.begin
                            ) {
                                booking.planned_origin_time = DateTime.fromISO(
                                    journeyRoute.begin
                                ).toISO();
                            }

                            if (
                                journeyRoute.node_type == 1 &&
                                journeyRoute.begin
                            ) {
                                booking.planned_destination_time =
                                    DateTime.fromISO(
                                        journeyRoute.begin
                                    ).toISO();
                            }

                            if (
                                booking.is_manually_allocated === true &&
                                booking.is_force_allocation !== true
                            ) {
                                booking.is_manually_allocated = false;
                            }
                        });
                    }
                );

                //Populate unscheduled items
                response.data.unscheduled.forEach((unscheduled) => {
                    let unscheduledBooking = bookings.value?.find(
                        (booking) => booking.uuid === unscheduled.booking_id
                    );

                    if (!unscheduledBooking) {
                        return;
                    }

                    unscheduledBooking.journey = null;
                });

                reloadTablesKey.value = Math.random();
            })
            .catch((response) => {
                errors.value.push(
                    "There was an issue automatically allocating bookings, please try again later."
                );
            })
            .finally(() => {
                regionAllocationStates.value[regionsStateIndex].completed =
                    true;
                optimiserProcessingModal.value?.hide();
            });
    };

    const asyncAutoAllocateRegion = (state: RegionAllocationState) => {
        let updatedRegionAllocationStates = regionAllocationStates.value;

        let regionsStateIndex = updatedRegionAllocationStates.findIndex(
            (i) => i.region === state.region
        );

        const bookingUuidsForRegion = selectedBookingsInUnAllocatedList.value
            .filter((booking) => booking.region?.uuid === state.region)
            .map((booking) => booking.uuid);

        const journeyUuidsForRegion = selectedJourneysForAllocation.value
            .filter((journey) => journey.region?.uuid === state.region)
            .map((i) => i.uuid);

        updatedRegionAllocationStates[regionsStateIndex].bookings =
            bookingUuidsForRegion;

        updatedRegionAllocationStates[regionsStateIndex].journeys =
            journeyUuidsForRegion;

        regionAllocationStates.value = updatedRegionAllocationStates;

        if (
            updatedRegionAllocationStates[regionsStateIndex].journeys.length ===
                0 ||
            updatedRegionAllocationStates[regionsStateIndex].bookings.length ===
                0
        ) {
            regionAllocationStates.value[regionsStateIndex].completed = true;
            return;
        }

        const optimiserScheduleParameters = {
            optimiser_solution:
                regionAllocationStates.value[regionsStateIndex].solutionId,
            bookings: regionAllocationStates.value[regionsStateIndex].bookings,
            journeys: regionAllocationStates.value[regionsStateIndex].journeys,
            date: date.value,
            force_allocate_all_journey_bookings: window.location.search?.includes("auto_allocate_on_load") ? true : false
        };

        Optimiser.asyncAutoAllocate(optimiserScheduleParameters)
            .then((response) => {
                getAutoAllocationSolutionDetail(
                    response.data.solution_id,
                    regionAllocationStates.value[regionsStateIndex],
                    regionsStateIndex
                );
            })
            .catch((response) => {
                errors.value.push(
                    "There was an issue automatically allocating bookings, please try again later."
                );
            });
    };

    const getAutoAllocationSolutionDetail = (
        solutionId,
        state: RegionAllocationState,
        regionsStateIndex: number
    ) => {
        Optimiser.asyncAutoAllocationSolutionDetail(solutionId).then(
            (response) => {
                // recall endpoint every 20s if its not completed yet
                if (response.data.status === "notReady") {
                    setTimeout(function () {
                        getAutoAllocationSolutionDetail(
                            solutionId,
                            state,
                            regionsStateIndex
                        );
                    }, 20000);
                } else if (response.data.status === "failed") {
                    reloadTablesKey.value = Math.random();
                    regionAllocationStates.value[regionsStateIndex].completed = true;

                    autoAllocationResponseValidationErrors.value.push(
                        `Optimiser return failed status.`
                    );

                    optimiserProcessingModal.value?.hide();
                } else {
                    optimiserSolutions.value.push({
                        region: state.region,
                        solution: response.data,
                    });

                    selectedBookingsInAllocatedList.value = [];
                    selectedBookingsInUnAllocatedList.value = [];
                    selectedJourneysForAllocation.value = [];

                    let region = regions.value.find(
                        (i) => i.uuid == state.region
                    );

                    if ((response?.data?.journeys ?? []).length === 0) {
                        autoAllocationResponseValidationErrors.value.push(
                            `No Vehicle Run was allocated with bookings in '${
                                region?.name ?? ""
                            }' region`
                        );
                        optimiserProcessingModal.value?.hide();
                        return;
                    }

                    //Populate scheduled items
                    (response?.data?.journeys ?? []).forEach(
                        (optimiserJourneyObject) => {
                            let journey_uuid =
                                optimiserJourneyObject.vehicle_id.split(
                                    "---"
                                )[1];

                            //See if any of the journeys we have in the store match the optimiser journey
                            let matchingJourney = journeys.value.find(
                                (journey) => {
                                    return journey.uuid == journey_uuid;
                                }
                            );

                            if (!matchingJourney) {
                                autoAllocationResponseValidationErrors.value.push(
                                    `No Vehicle Run matched optimiser response for ${optimiserJourneyObject.vehicle_id}`
                                );
                                return;
                            }

                            if (optimiserJourneyObject.driver_id) {
                                Driver.show(
                                    optimiserJourneyObject.driver_id
                                ).then((response) => {
                                    if (matchingJourney) {
                                        matchingJourney.driver = response.data;
                                    }
                                });
                            }

                            matchingJourney.start_time = DateTime.fromISO(
                                optimiserJourneyObject.depart_depot
                            ).toISO();
                            matchingJourney.end_time = DateTime.fromISO(
                                optimiserJourneyObject.arrive_at_depot
                            ).toISO();

                            let journeyRoutes: OptimiserScheduleResponseJourneyRouteObject[] =
                                optimiserJourneyObject?.journey_route ?? [];

                            if (journeyRoutes.length === 0) {
                                autoAllocationResponseValidationErrors.value.push(
                                    `None of the selected bookings could be assigned to Vehicle Run ${matchingJourney.uuid}`
                                );
                                return;
                            }

                            //Handle breaks
                            let breakJourneyRoute = journeyRoutes.find(
                                (journeyRoute) =>
                                    journeyRoute.node_type == 4 &&
                                    journeyRoute.begin &&
                                    journeyRoute.depart
                            );

                            matchingJourney.breaks = [];

                            if (breakJourneyRoute) {
                                let newBreaks = [
                                    {
                                        start_time: DateTime.fromISO(
                                            breakJourneyRoute.begin
                                        ).toISO(),
                                        end_time: DateTime.fromISO(
                                            breakJourneyRoute.depart
                                        ).toISO(),
                                    },
                                ];
                                //@ts-ignore
                                matchingJourney.breaks = newBreaks;
                            }

                            journeyRoutes.forEach((journeyRoute) => {
                                if (!journeyRoute.booking_id) {
                                    return;
                                }
                                let booking = bookings.value.find(
                                    (booking) =>
                                        booking.uuid === journeyRoute.booking_id
                                );
                                if (!booking) {
                                    return;
                                }

                                booking.journey = matchingJourney;
                                if (
                                    journeyRoute.node_type == 0 &&
                                    journeyRoute.begin
                                ) {
                                    booking.planned_origin_time =
                                        DateTime.fromISO(
                                            journeyRoute.begin
                                        ).toISO();
                                }

                                if (
                                    journeyRoute.node_type == 1 &&
                                    journeyRoute.begin
                                ) {
                                    booking.planned_destination_time =
                                        DateTime.fromISO(
                                            journeyRoute.begin
                                        ).toISO();
                                }
                            });
                        }
                    );

                    //Populate unscheduled items
                    response.data.unscheduled.forEach((unscheduled) => {
                        let unscheduledBooking = bookings.value?.find(
                            (booking) => booking.uuid === unscheduled.booking_id
                        );

                        if (!unscheduledBooking) {
                            return;
                        }

                        unscheduledBooking.journey = null;
                    });

                    reloadTablesKey.value = Math.random();
                    regionAllocationStates.value[regionsStateIndex].completed = true;
                    optimiserProcessingModal.value?.hide();
                }
            }
        );
    };

    const unscheduledReasons = computed(() => {
        let reasons: OptimiserUnscheduledReason[] = [];

        optimiserSolutions.value?.forEach((solution) => {
            return solution.solution.unscheduled?.forEach((i) =>
                reasons.push(i)
            );
        });

        return reasons;
    });

    const constraintViolations = computed(() => {
        let violations: OptimiserConstraintViolation[] = [];

        optimiserSolutions.value?.forEach((solution) => {
            solution.solution.journeys?.forEach((journey) => {
                (journey?.journey_route ?? [])?.forEach((journeyRoute) => {
                    if (
                        !journeyRoute.booking_id ||
                        (journeyRoute.constraint_violation ?? "").length === 0
                    ) {
                        return;
                    }

                    violations.push({
                        booking_id: journeyRoute.booking_id,
                        constraint_violation: journeyRoute.constraint_violation,
                    });
                });
            });
        });

        return violations;
    });

    const constraintViolationsForBooking = (booking: App.Models.Booking) => {
        return constraintViolations.value
            .filter((i) => i.booking_id === booking.uuid)
            .map((i) => i.constraint_violation);
    };

    const unscheduledReasonForBooking = (booking: App.Models.Booking) => {
        return unscheduledReasons.value.find(
            (i) => i.booking_id === booking.uuid
        );
    };

    const bookingsAllocatedToJourney = (
        journey: App.Models.Journey,
        useOriginalBookings: boolean = false
    ) => {
        const undesiredBookingStatuses: string[] = [
            BookingStatus.Cancelled,
            BookingStatus.Standby,
        ];
        return (useOriginalBookings ? originalBookings : bookings).value.filter(
            (booking) => {
                return (
                    booking?.journey?.uuid == journey.uuid &&
                    !undesiredBookingStatuses.includes(booking.status)
                );
            }
        );
    };

    const autoAllocateBookings = () => {
        if (autoAllocationDisabledMessage.value.length > 0) {
            autoAllocationDisabledErrors.value =
                autoAllocationDisabledMessage.value;

            setTimeout(() => {
                autoAllocationDisabledErrors.value = [];
            }, 5000);
            return;
        }

        autoAllocationResponseValidationErrors.value = [];

        autoAllocationDisabledErrors.value = [];
        errors.value = [];
        unscheduledByOptimiser.value = [];
        regionAllocationStates.value = regions.value.map((region) => {
            return {
                region: region.uuid,
                solutionId:
                    optimiserSolutions?.value?.[0]?.solution?.solution_id,
                bookings: [],
                journeys: [],
                completed: false,
            };
        });

        optimiserProcessingModal.value?.show();
        regionAutoAllocationSolutions.value = [];
        regionAllocationStates.value.forEach((regionState) =>
            asyncAutoAllocateRegion(regionState)
        );
    };

    const getUpdateParametersForJourney = (journey: App.Models.Journey) => {
        let draftJourney = journeys.value.find((i) => i.uuid == journey.uuid);
        let originalJourney = originalJourneys.value.find(
            (i) => i.uuid == journey.uuid
        );

        let updateParameters: any = {};

        if (!draftJourney || !originalJourney) {
            return updateParameters;
        }

        if (draftJourney?.driver?.uuid !== originalJourney?.driver?.uuid) {
            updateParameters.driver_uuid = draftJourney.driver?.uuid;
        }

        if (draftJourney?.vehicle?.uuid !== originalJourney?.vehicle?.uuid) {
            updateParameters.vehicle_uuid = draftJourney.vehicle?.uuid;
        }

        if (draftJourney?.driver?.uuid !== originalJourney?.driver?.uuid) {
            updateParameters.driver_uuid = draftJourney.driver?.uuid;
        }

        let draftJourneyDatetime = new JourneyDatetime(draftJourney);
        let originalJourneyDatetime = new JourneyDatetime(originalJourney);

        if (
            draftJourneyDatetime.startTime()?.toFormat(timeComparisonFormat) !==
            originalJourneyDatetime.startTime()?.toFormat(timeComparisonFormat)
        ) {
            updateParameters.start_time = draftJourneyDatetime
                .startTime()
                ?.toSeconds();
        }

        if (
            draftJourneyDatetime.endTime()?.toFormat(timeComparisonFormat) !==
            originalJourneyDatetime.endTime()?.toFormat(timeComparisonFormat)
        ) {
            updateParameters.end_time = draftJourneyDatetime
                .endTime()
                ?.toSeconds();
        }

        let newBreaks = draftJourney?.breaks?.map((i) => {
            return {
                start_time: DateTime.fromISO(i.start_time).toSeconds(),
                end_time: DateTime.fromISO(i.end_time ?? "").toSeconds(),
            };
        });

        if (draftJourney?.breaks?.length !== originalJourney?.breaks?.length) {
            updateParameters.breaks = newBreaks;
        } else {
            let breaksChanged = false;

            draftJourney?.breaks?.forEach((i, index) => {
                let startChanged =
                    DateTime.fromISO(i.start_time).toSeconds() !==
                    DateTime.fromISO(
                        originalJourney?.breaks?.[index]?.start_time ?? ""
                    ).toSeconds();
                let endChanged =
                    DateTime.fromISO(i.end_time ?? "").toSeconds() !==
                    DateTime.fromISO(
                        originalJourney?.breaks?.[index]?.end_time ?? ""
                    ).toSeconds();

                if (startChanged || endChanged) {
                    breaksChanged = true;
                }
            });

            if (breaksChanged) {
                updateParameters.breaks = newBreaks;
            }
        }

        return updateParameters;
    };

    const getUpdateParametersForBooking = (booking: App.Models.Booking) => {
        let draftBooking = bookings.value.find((i) => i.uuid == booking.uuid);
        let originalBooking = originalBookings.value.find(
            (i) => i.uuid == booking.uuid
        );

        let updateParameters: any = {};

        if (!draftBooking || !originalBooking) {
            return updateParameters;
        }

        let draftBookingDatetime = new BookingDatetime(draftBooking);
        let originalBookingDatetime = new BookingDatetime(originalBooking);

        if (
            draftBookingDatetime
                .plannedOriginTime()
                ?.toFormat(timeComparisonFormat) !==
            originalBookingDatetime
                .plannedOriginTime()
                ?.toFormat(timeComparisonFormat)
        ) {
            updateParameters.planned_origin_time = draftBookingDatetime
                .plannedOriginTime()
                ?.toSeconds();
        }

        if (
            draftBookingDatetime
                .plannedDestinationTime()
                ?.toFormat(timeComparisonFormat) !==
            originalBookingDatetime
                .plannedDestinationTime()
                ?.toFormat(timeComparisonFormat)
        ) {
            updateParameters.planned_destination_time = draftBookingDatetime
                .plannedDestinationTime()
                ?.toSeconds();
        }

        if (draftBooking.journey?.uuid !== originalBooking.journey?.uuid) {
            updateParameters.journey_uuid = draftBooking.journey?.uuid ?? null;
        }

        if (draftBooking.status !== originalBooking.status) {
            updateParameters.status = draftBooking.status;
        }

        if (
            draftBooking.is_force_allocation !==
            originalBooking.is_force_allocation
        ) {
            updateParameters.is_force_allocation =
                draftBooking.is_force_allocation;
        }

        if (
            draftBooking.is_manually_allocated !==
            originalBooking.is_manually_allocated
        ) {
            updateParameters.is_manually_allocated =
                draftBooking.is_manually_allocated;
        }

        return updateParameters;
    };

    const getSaveAllocationChangesPayload =
        (): SaveAllocationChangesRequestInterface => {
            let payload: SaveAllocationChangesRequestInterface = {
                bookings: [],
                journeys: [],
            };

            bookings.value.forEach((booking) => {
                let updateParameters = getUpdateParametersForBooking(booking);

                if (Object.keys(updateParameters).length > 0) {
                    payload.bookings.push({
                        uuid: booking.uuid,
                        ...updateParameters,
                    });
                }
            });

            journeys.value.forEach((journey) => {
                let updateParameters = getUpdateParametersForJourney(journey);

                if (Object.keys(updateParameters).length > 0) {
                    payload.journeys.push({
                        uuid: journey.uuid,
                        ...updateParameters,
                    });
                }
            });

            return payload;
        };

    const bookingHasUnsavedChanges = (booking: App.Models.Booking) => {
        let updateParameters = getUpdateParametersForBooking(booking);

        return Object.keys(updateParameters).length > 0;
    };

    const journeyHasUnsavedChanges = (journey: App.Models.Journey) => {
        let updateParameters = getUpdateParametersForJourney(journey);

        return Object.keys(updateParameters).length > 0;
    };

    const autoAllocationDisabledMessage = computed(() => {
        let messages: string[] = [];
        if (allocationsPageHasUnsavedChanges.value) {
            messages.push(
                "Cannot auto allocate when you have unsaved changes in the allocations page"
            );
        } else if (
            selectedJourneysForAllocation.value.length == 0 ||
            selectedBookingsInUnAllocatedList.value.length == 0
        ) {
            messages.push(
                "Cannot auto allocate when no bookings or vehicle runs are selected"
            );
        }

        return messages;
    });

    const saveChanges = () => {
        savingChanges.value = true;
        errors.value = [];
        bookingTimeValidationErrors.value = [];
        vehicleRunTimeValidationErrors.value = [];

        const allocationChangesPayload = getSaveAllocationChangesPayload();

        Booking.saveAllocationChanges(allocationChangesPayload)
            .then((response) => {
                bookings.value = [];
                journeys.value = [];
                getJourneys();
                getBookings();
                optimiserSolutions.value = [];
                changedSaved.value = true;
            })
            .catch((response) => {
                if (response.response.status === 422) {
                    for (let error in response.response.data.errors) {
                        const message = response.response.data.errors[error][0];
                        if (message.includes("time")) {
                            bookingTimeValidationErrors.value.push({
                                field: message.includes("Origin")
                                    ? "planned_origin_time"
                                    : "planned_destination_time",
                                booking_uuid: message.split(":")[0],
                                message: message.split(":")[1],
                            });
                        }

                        if (
                            response.response.data.errors.vehicle &&
                            response.response.data.errors.vehicle.length > 0
                        ) {
                            for (
                                let i = 0;
                                i <
                                response.response.data.errors.vehicle.length;
                                i++
                            ) {
                                vehicleRunTimeValidationErrors.value.push(
                                    response.response.data.errors.vehicle[i]
                                );
                            }
                        }
                    }
                } else {
                    errors.value = response.response.data.message;
                }

                changedSaved.value = false;
            })
            .finally(() => {
                savingChanges.value = false;
                showManualOverrideWarning.value = false;
                autoAllocatePressed.value = false;
            });
    };

    const changedBookings = computed((booking: App.Models.Booking) => {
        return bookings.value?.filter((booking) => {
            return bookingHasUnsavedChanges(booking);
        });
    });

    const allocationsPageHasUnsavedChanges = computed(() => {
        return (
            bookings.value?.some((booking) => {
                return bookingHasUnsavedChanges(booking);
            }) ||
            journeys.value?.some((journey) => {
                return journeyHasUnsavedChanges(journey);
            })
        );
    });

    const vehicles = computed(() => {
        // group all the journeys by their vehicle
        const vehicles: {
            vehicle: App.Models.Vehicle;
            runs: App.Models.Journey[];
        }[] = [];

        journeys.value.forEach((journey) => {
            if (!journey.vehicle) {
                return;
            }
            let vehicleUuid = journey.vehicle?.uuid;
            if (!vehicleUuid) {
                return;
            }
            let existingVehicle = vehicles.find(
                (i) => i.vehicle.uuid === vehicleUuid
            );

            if (existingVehicle) {
                let index = vehicles.indexOf(existingVehicle);
                vehicles[index].runs.push(journey);
            } else {
                vehicles.push({
                    vehicle: journey.vehicle,
                    runs: [journey],
                });
            }
        });

        // sort the vehicles by their description
        return vehicles.sort((a: any, b: any) => {
            return a.vehicle.description?.localeCompare(b.vehicle.description);
        });
    });

    const checkAllocationRequirements = (
        bookings: BookingResource[] | Booking[],
        journey: App.Models.Journey | undefined = selectedJourney.value
    ): boolean => {
        if (!journey?.driver) {
            addToastMessage(
                "error",
                "Currently manually allocating to a vehicle run without a driver is not supported. Please allocate a driver to this vehicle run and try again."
            );
            return false;
        }

        if (journey?.vehicle?.location_restrictions) {
            return checkVehicleLocationRestrictions(bookings, journey);
        }

        return true;
    };

    const allocationSearchFilter = (
        booking: BookingResource | Booking,
        phrase: string,
        searchBy: string
    ): boolean => {
        const stringIncludesQueryString = (
            string: string | undefined | null
        ) => {
            return (
                string?.toLowerCase()?.includes(phrase.trim().toLowerCase()) ??
                false
            );
        };

        if (searchBy === "Locations") {
            return (
                stringIncludesQueryString(booking?.origin?.full_address) ||
                stringIncludesQueryString(booking?.destination?.full_address)
            );
        }
        //@ts-ignore
        return stringIncludesQueryString(booking?.client?.full_name) || stringIncludesQueryString(booking?.client?.preferred_name);
    };

    const matchesAllocationSearchPhrase = (
        booking: BookingResource | Booking
    ): boolean => {
        return allocationSearchFilter(
            booking,
            allocationSearchPhrase.value,
            allocationSearchBy.value
        );
    };

    const matchesAllocationAllocatedSectionSearchPhrase = (
        booking: BookingResource | Booking
    ): boolean => {
        return allocationSearchFilter(
            booking,
            allocationAllocatedSectionSearchPhrase.value,
            allocationAllocatedSectionSearchBy.value
        );
    };

    const checkVehicleLocationRestrictions = (
        bookings: BookingResource[] | Booking[],
        journey: App.Models.Journey
    ): boolean => {
        const restrictedLocations = (
            journey?.vehicle?.location_restrictions ?? []
        ).map((restriction) => {
            return restriction?.location?.uuid;
        });

        each(bookings, (booking) => {
            if (restrictedLocations.includes(booking?.origin?.uuid)) {
                //@ts-ignore
                allocationErrors.value[booking.uuid] =
                    "Vehicle Location Restriction for Origin";
                addToastMessage(
                    "error",
                    "Allocated Bookings have Allocation Issues"
                );
                return false;
            }

            if (restrictedLocations.includes(booking?.destination?.uuid)) {
                //@ts-ignore
                allocationErrors.value[booking.uuid] =
                    "Vehicle Location Restriction for Destination";
                addToastMessage(
                    "error",
                    "Allocated Bookings have Allocation Issues"
                );
                return false;
            }
        });

        return true;
    };

    const vehicleDepots = computed<App.Models.Location[]>(() => {
        let depots: App.Models.Location[] = [];

        vehicles.value.forEach((vehicle) => {
            if (!vehicle.vehicle?.depot) {
                return;
            }

            if (depots.find((i) => i.uuid === vehicle.vehicle?.depot?.uuid)) {
                return;
            }

            depots.push(vehicle.vehicle?.depot);
        });

        return depots;
    });

    return {
        adjustments,
        bookings,
        originalBookings,
        errors,
        allocatedBookingsFilters,
        vehicleDepots,
        autoAllocationDisabledErrors,
        autoAllocationResponseValidationErrors,
        bookingTimeValidationErrors,
        vehicleRunTimeValidationErrors,
        changedSaved,
        journeys,
        originalJourneys,
        loadingJourneys,
        optimiserSolutions,
        selectedJourney,
        date,
        showManualOverrideWarning,
        reloadTablesKey,
        unscheduledByOptimiser,
        regionAllocationStates,
        optimiserProcessingModal,
        loadingOptimiserSolutions,
        savingChanges,
        optimiserSettings,
        optimiserSettingsModal,
        optimiserUnscheduledModal,
        regions,
        loadingBookings,
        selectedBookingsInAllocatedList,
        selectedBookingsInUnAllocatedList,
        selectedJourneysForAllocation,
        expandedVehicleIndexes,
        vehicles,
        allocationsPageHasUnsavedChanges,
        allocationErrors,
        allocationSearchPhrase,
        allocationSearchBy,
        allocationAllocatedSectionSearchPhrase,
        allocationAllocatedSectionSearchBy,
        matchesAllocationSearchPhrase,
        matchesAllocationAllocatedSectionSearchPhrase,
        bookingsAllocatedToJourney,
        getUpdateParametersForJourney,
        getJourneys,
        getBookings,
        autoAllocateBookings,
        bookingHasUnsavedChanges,
        journeyHasUnsavedChanges,
        saveChanges,
        unscheduledReasonForBooking,
        constraintViolationsForBooking,
        autoAllocationDisabledMessage,
        unscheduledReasons,
        changedBookings,
        checkAllocationRequirements,
        autoAllocatePressed,
    };
});
