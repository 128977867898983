import { ref } from "vue";
import { defineStore, storeToRefs } from "pinia";
import Vehicle from "@classes/Vehicle";
import { useMessageStore } from "@stores/MessageStore";

export const useVehicleStore = defineStore('vehicleStore', () => {

    const messageStore = useMessageStore();
    const { addToastMessage, addFieldError } = messageStore;

    const vehicleOptions = ref<App.Resource.VehicleOptionsResource[]>();

    const vehicleError = ref<string>('');
    const loadingVehicleOptions = ref<boolean>(false); 

    const getVehicleOptions = () => {
        loadingVehicleOptions.value = true;

        Vehicle.getOptions()
        .then((resp) => {
            vehicleOptions.value = resp.data.data;
        })
        .catch((err) => {
            addToastMessage('error', 'Failed to get Vehicle options');
            console.error(err);
        })
        .finally(() => {
            loadingVehicleOptions.value = false;
        })
    }

    return {
        vehicleOptions,
        vehicleError,
        loadingVehicleOptions,
        getVehicleOptions,
    }
})