<template>
    <div :id="listModalId" class="modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">Activity Logs</h4>
                </div>
                <div class="modal-body pb-3">
                    <div class="d-flex justify-content-center" v-if="loading">
                        <Loading
                            :show-text="false"
                            spinner-class="spinner-grow"
                        />
                    </div>
                    <div class="table-responsive" v-if="!loading">
                        <table
                            class="table table-xs table-striped table-bordered"
                        >
                            <thead>
                                <tr>
                                    <th style="white-space: nowrap">Event</th>
                                    <th style="white-space: nowrap">Causer</th>

                                    <th style="white-space: nowrap">
                                        Occured At
                                    </th>
                                    <th style="white-space: nowrap">
                                        View Properties Changed
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="(
                                        activityLog, key
                                    ) in activityLogs?.data ?? []"
                                    :key="activityLog?.id ?? key"
                                >
                                    <tr>
                                        <td
                                            :class="{
                                                'align-middle': true,
                                                'text-center': true,
                                            }"
                                        >
                                            <span
                                                :class="{
                                                    badge: true,
                                                    'text-bg-primary': ![
                                                        'created',
                                                        'deleted',
                                                    ].includes(
                                                        activityLog.event
                                                    ),
                                                    'text-bg-success':
                                                        activityLog.event ===
                                                        'created',
                                                    'text-bg-danger':
                                                        activityLog.event ===
                                                        'deleted',
                                                }"
                                                >{{ activityLog.event }}</span
                                            >
                                        </td>
                                        <td class="align-middle">
                                            {{
                                                activityLog.causer?.name ??
                                                "Unknown or deleted user"
                                            }}
                                        </td>

                                        <td class="align-middle">
                                            {{
                                                DateTime.fromISO(
                                                    activityLog.created_at ?? ""
                                                ).toLocaleString(
                                                    DateTime.DATETIME_MED_WITH_WEEKDAY
                                                )
                                            }}
                                        </td>
                                        <td
                                            class="d-flex flex-row justify-content-end"
                                        >
                                            <Button
                                                class="btn btn-secondary btn-sm w-100"
                                                @click="
                                                    propertiesModalActivityId =
                                                        activityLog.id
                                                "
                                            >
                                                <SvgIcon
                                                    type="mdi"
                                                    :path="mdiEyeOutline"
                                                    :size="17"
                                                    class="me-1"
                                                />
                                                {{
                                                    Object.keys(
                                                        activityLog.properties
                                                            .attributes
                                                    ).length
                                                }}
                                                Properties Changed
                                            </Button>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        v-if="!loading && paginationMeta"
                        @pageChanged="pageChanged"
                        :pagination="paginationMeta"
                        record-name="activity logs"
                    ></Pagination>
                </div>
                <div class="modal-footer">
                    <Button class="btn btn-secondary" @click="close">
                        Close
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <div
        :id="propertiesModalId"
        class="modal fade"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="header-title mt-0">Properties Changed</h4>
                </div>
                <div class="modal-body pb-3">
                    <div class="table-responsive">
                        <table
                            class="table table-xs table-striped table-bordered"
                        >
                            <thead>
                                <tr>
                                    <th style="white-space: nowrap">
                                        Property
                                    </th>
                                    <th style="white-space: nowrap">New</th>
                                    <th style="white-space: nowrap">Old</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template
                                    v-for="key in Object.keys(
                                        propertiesToShow?.attributes ?? {}
                                    ) ?? []"
                                >
                                    <ActivityLogPropertiesChangedRow
                                        :propertiesToShow="propertiesToShow"
                                        :attribute="key"
                                    />
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="modal-footer">
                        <Button
                            class="btn btn-secondary"
                            @click="propertiesModalActivityId = undefined"
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.modal {
    z-index: 1057;
}
</style>
<script setup lang="ts">
import ActivityLog from "@classes/ActivityLog";
import ActivityLogPropertiesChangedRow from "@components/ActivityLogsModalPlugin/ActivityLogPropertiesChangedRow.vue";
import Button from "@components/Button.vue";
import Loading from "@components/Loading.vue";
import Pagination from "@components/Pagination.vue";
import {LaravelPaginate} from "@customTypes/LaravelCommonTypes";
import ActivityLogResource from "@customTypes/resources/ActivityLogResource";
import SvgIcon from "@jamescoyle/vue-icon";
import {mdiEyeOutline} from "@mdi/js";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle";
import {DateTime} from "luxon";
import {computed, inject, onMounted, ref, watch} from "vue";

const activityLogSubjectType = inject<any>("activityLogSubjectType");
const activityLogSubjectUuid = inject<any>("activityLogSubjectUuid");

const loading = ref(false);

const listModalId = ref("ActivityLogsModal");
const propertiesModalId = ref("ActivityLogsPropertiesModal");
//@ts-ignore
const listModal = ref<null | bootstrap.Modal>(null);
const propertiesModal = ref<null | bootstrap.Modal>(null);

const propertiesModalActivityId = ref<number | undefined>();

const page = ref(1);

const pageChanged = (link) => {
    page.value = link.page;
    loadActivityLogs();
};

const activityLogs = ref<LaravelPaginate<ActivityLogResource> | undefined>();
const paginationMeta = ref<any>({});

const emit = defineEmits<{
    (e: "update:show", value: boolean): void;
}>();

const show = computed({
    get() {
        return activityLogSubjectType.value && activityLogSubjectUuid.value;
    },
    set(value) {
        if (!value) {
            activityLogSubjectType.value = null;
            activityLogSubjectUuid.value = null;
        }
    },
});

const isDatetimeAttribute = (key: string) => {
    return (
        key.includes("_at") ||
        key.includes("_time") ||
        key.includes("date") ||
        [
            "arrive_destination_not_before",
            "arrive_destination_no_later_than",
            "depart_origin_not_before",
            "depart_origin_no_later_than",
        ].includes(key)
    );
};

const getDisplayablePropertyValueByKey = (
    key: string,
    attribute: "old" | "attributes"
): string => {
    if (!propertiesToShow.value) {
        return "-";
    }

    if (!propertiesToShow.value?.[attribute]?.[key]) {
        return "-";
    }

    if (isDatetimeAttribute(key)) {
        try {
            return DateTime.fromISO(
                propertiesToShow.value?.[attribute]?.[key]
            ).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
        } catch (e) {
            console.error(e);
        }
    }

    return propertiesToShow.value?.[attribute]?.[key];
};

const close = () => {
    show.value = false;
    // emit("update:show", false);
};

const initModal = () => {
    const modalElement = document.getElementById(listModalId.value);
    if (!modalElement) {
        return;
    }
    listModal.value = new bootstrap.Modal(modalElement, {});

    const propertiesModalElement = document.getElementById(
        propertiesModalId.value
    );
    if (!propertiesModalElement) {
        return;
    }
    propertiesModal.value = new bootstrap.Modal(propertiesModalElement, {});
};

const loadActivityLogs = () => {
    loading.value = true;
    ActivityLog.index({
        subject_type: activityLogSubjectType.value,
        subject_uuid: activityLogSubjectUuid.value,
        page: page.value,
    })
        .then((response) => {
            activityLogs.value = response.data;
            paginationMeta.value = response.data.meta;
        })
        .finally(() => {
            loading.value = false;
        });
};

const propertiesToShow = computed(() => {
    if (!activityLogs.value) {
        return null;
    }
    const activityLog = activityLogs.value.data.find(
        (i) => i.id === propertiesModalActivityId.value
    );
    if (!activityLog) {
        return null;
    }
    return activityLog.properties;
});

watch(
    () => propertiesModalActivityId.value,
    (newValue) => {
        if (newValue && propertiesModal.value) {
            propertiesModal.value?.show();
        } else {
            propertiesModal.value?.hide();
        }
    }
);

watch(
    () => show.value,
    (newValue) => {
        if (newValue && listModal.value) {
            listModal.value?.show();
            loadActivityLogs();
        } else {
            activityLogs.value = undefined;
            listModal.value?.hide();
        }
    }
);

onMounted(() => {
    initModal();
});
</script>
